"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./navbar.module.css";
import SmallMenu from "./smallMenu";
import { usePathname } from "next/navigation";

export default function NavBar() {
  const [activeLink, setActiveLink] = useState("/dashboard")
  const [accessToken, setAccessToken] = useState(null);
  const token = useSelector((state) => state.userToken.value.token);
  useEffect(() => {
    setAccessToken(token);
  }, [token]);

  const url = usePathname()
  useEffect(() => {
    setActiveLink(url)
  }, [url])

  return (
    <Navbar expand="lg" className="">

      <Container>
        <Link href={accessToken ? "/assessment" : "/"} className=" navbar-brand d-flex mt-2">

          {accessToken ? (
            <Image
              src="/logo-text.png"
              alt="Picture of the author"
              width={175}
              height={50}
              className="d-none d-md-block"
              priority
            />) : (
            <Image
              src="/logo-text.png"
              width={265}
              height={75}
              alt="Picture of the author"
              className="d-none d-md-block"
              priority
            />)}
          <Image
            src="/logo-text.png"
            width={175}
            height={50}
            alt="Picture of the author"
            className="d-md-none d-block"
            priority
          />
        </Link>

        {accessToken && (
          <>
            {/* <Link href="/" className="navbar-brand d-flex mt-2">
            <Image
              src="/logo-text.png"
              alt="Picture of the author"
              width={200}
              height={50}
              className="d-none d-md-block"
              priority
            />
          </Link> */}

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="w-100 justify-content-evenly align-items-center">

                <Link href="/dashboard" className={`${activeLink === "/dashboard" && styles.navLink} nav-link`}>
                  DASHBOARD
                </Link>
                <Link href="/journal" className={`${activeLink === "/journal" && styles.navLink} nav-link`}>
                  JOURNAL
                </Link>
                <Link href="/tasks" className={`${activeLink === "/tasks" && styles.navLink} nav-link`}>
                  TASKS
                </Link>
                <Link href="/videos" className={`${activeLink === "/videos" && styles.navLink} nav-link`}>
                  VIDEOS
                </Link>
                <Link href="/movies" className={`${activeLink === "/movies" && styles.navLink} nav-link`}>
                  MOVIES
                </Link>
                <Link href="/readings" className={`${activeLink === "/readings" && styles.navLink} nav-link`}>
                  BOOKS
                </Link>
                <Link href="/quotes" className={`${activeLink === "/quotes" && styles.navLink} nav-link`}>
                  QUOTES
                </Link>
                <Link href="/reports" className={`${activeLink === "/reports" && styles.navLink} nav-link`}>
                  REPORTS
                </Link>

                <Navbar.Text className="d-flex align-items-center">
                  <SmallMenu />
                </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
}
