import { Button, Dropdown } from "react-bootstrap";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { logout } from "@store/userLoginSlice";
import { useDispatch, useSelector } from "react-redux";
import style from "./navbar.module.css";
import { useRouter } from "next/navigation";

const SmallMenu = () => {
  const userData = useSelector((state) => state.userToken.value.user);
  // const [user, setUser] = useState({});
  const [profileImage, setProfileImage] = useState(null)

  useEffect(() => {
    if (userData) {

      setProfileImage(userData && userData.profileImage);
    }
  }, [userData]);
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick, href }, ref) => (
    <Link
      href=""
      ref={ref}
      className="text-decoration-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled mb-0 pt-1 pb-1">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const dispatch = useDispatch();
  const router = useRouter();
  const handleLogout = () => {
    dispatch(logout());
    router.push("/login");
  };

  return (
    <Dropdown show={true} className={style.dropdownContainer}>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        className="p-2 mt-5 bg-success rounded-circle "
      >
        {
          profileImage &&
          <span className="d-block text-center">

            <Image
              src={profileImage && `https://mindbalance-assets.s3.us-west-1.amazonaws.com/avatars/${profileImage}`}
              // src={user && `https://${process.env.NEXT_PUBLIC_AWS_URL}/avatars/${user.profileImage}`}
              width={40}
              height={40}
              alt="Profile Image"
              className={`rounded-circle me-2 profile-image`}
              style={{ border: "1px solid #AAE4EA" }}
            />
          </span>
        }
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className={`border-0 shadow-sm ${style.dropdownMenu}`}
      >
        <Link
          href={"/profile"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          My Profile
        </Link>
        <hr className="mt-1 mb-1" />
        <Link
          href={"/assessment/self"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          Self Assessment
        </Link>
        <Link
          href={"/assessment/friends-family"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          Friends Family
        </Link>
        <Link
          href={"/assessment/match-celebrity"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          Match Celebrity
        </Link>
        <Link
          href={"/videos"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          Videos
        </Link>

        <Link
          href={"/referral-link"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          Referral Link
        </Link>

        <Link
          href={"/pages/gamification"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          Gamification
        </Link>

        <Link
          href={"/professional-info"}
          className={`dropdown-item ${style.dropdownItem}`}
        >
          Advertise
        </Link>
        <hr className="mt-1 mb-1" />
        <Button
          variant="link"
          className={`dropdown-item text-dark ${style.dropdownItem}`}
          onClick={() => handleLogout()}
        >
          Logout
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default SmallMenu;
